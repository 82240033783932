import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import api from "app/api";
import { useUsername } from "app/auth";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

import { useIsLoggedIn } from "app/auth";
import { useReady } from "app/store";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import trans from "trans";
import LoadData from "./LoadData";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [, saveUsername] = useUsername();
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [newPassword, setNewPassword] = React.useState("");
  const [isFirstLogin, setFirstLogin] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const [ready] = useReady();

  const isLoggedIn = useIsLoggedIn();

  function login(username, password) {
    setLoading(true);
    api
      .login(username, password)
      .then((result) => {
        localStorage.setItem("token", result.data.access_token);
        saveUsername(username);
        if (username !== "A") {
          //setKund()
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error === "first_login") {
          setFirstLogin(true);
        } else {
          alert(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function changePassword() {
    setLoading(true);
    api.Account.Register(username, password, newPassword)
      .then((result) => {
        console.log("success: ", result);
        setFirstLogin(false);
        login(username, newPassword);
      })
      .catch((error) => {
        console.log("error: ", error.response);
        alert(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  if (isFirstLogin) {
    const validPassword = newPassword.length >= 6 && newPassword === confirmPassword;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card className={classes[cardAnimaton]}>
              <CardBody>
                <p>
                  <strong>You need to change your default password</strong>
                </p>
              </CardBody>
            </Card>

            <br />

            <form onSubmit={login}>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                  <img
                    className="App-logo"
                    src={require("assets/img/esmart.png")}
                    width="150"
                    alt="eSmart"
                  />
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Username"
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,

                      onChange: ({ target }) => {
                        setUsername(target.value);
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <CustomInput
                    labelText="New Password"
                    id="newpassword"
                    formControlProps={{
                      fullWidth: true,
                      focused: true,
                    }}
                    helperText={
                      newPassword.length > 0 && newPassword.length < 6
                        ? "The Password must be at least 6 characters long."
                        : undefined
                    }
                    inputProps={{
                      disabled: loading,

                      onChange: ({ target }) => {
                        setNewPassword(target.value.trim());
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      name: "new-password",
                      value: newPassword,
                      autoComplete: "off",
                    }}
                  />

                  <CustomInput
                    labelText="Confirm password"
                    id="confirmpassword"
                    formControlProps={{
                      fullWidth: true,
                      focused: true,
                    }}
                    helperText={
                      confirmPassword.length > 0 && confirmPassword !== newPassword
                        ? "Must match the new password"
                        : undefined
                    }
                    inputProps={{
                      disabled: loading,

                      onChange: ({ target }) => {
                        setConfirmPassword(target.value.trim());
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      name: "new-password-confirmation",
                      value: confirmPassword,
                    }}
                  />
                </CardBody>

                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    block
                    size="lg"
                    color="rose"
                    onClick={changePassword}
                    disabled={loading || !validPassword}
                    variant="outlined">
                    {trans("change password")}
                  </Button>
                </CardFooter>
                {loading && (
                  <CustomLinearProgress variant="indeterminate" color="primary" value={30} />
                )}
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  if (isLoggedIn && !ready) {
    return <LoadData {...{ classes }} />;
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card className={classes[cardAnimaton]}>
            <CardBody>
              <p>
                <strong>eSmart har ett fått en ny design och uppdaterad funktionalitet.</strong>
              </p>
              <p>
                Vi hoppas att vi kan försätta bidra till ert miljöarbete och att vi tillsammans kan
                bli bättre på att återvinna.
              </p>
              <p>Vi önskar en trevlig dag!</p>

              <hr />
              <p>
                <strong>
                  eSmart has been through a renovation and update. We have added a new design as
                  well as various functions.
                </strong>
              </p>
              <p>
                We hope that you will appreciate all the added value and that we help push your
                recycling ambitions.
              </p>
              <p>Have a great day!</p>
            </CardBody>
          </Card>

          <br />

          <form onSubmit={login}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                <img src={require("assets/img/esmart.png")} width="150" alt="eSmart" />
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: loading,

                    onChange: ({ target }) => {
                      setUsername(target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />

                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: loading,

                    onChange: ({ target }) => {
                      setPassword(target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "password",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  disabled={loading}
                  color="rose"
                  variant="outlined"
                  size="lg"
                  block
                  onClick={() => login(username, password)}>
                  {trans("login")}
                </Button>
              </CardFooter>
              {loading && (
                <CustomLinearProgress variant="indeterminate" color="primary" value={30} />
              )}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
