import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Logo from "./Logo";
import React from "react";
import { useUsername } from "app/auth";
import { useReady } from "app/store";
import { useAllaKunder } from "app/store";
import api from "app/api";

export default function LoadData({ classes }) {
  const [username, saveUsername] = useUsername();
  const [ready, setReady] = useReady();
  const [, setAllaKunder] = useAllaKunder();

  React.useEffect(() => {
    if (!ready) {
      api.Account.Kundnr(username)
        .then((items) => {
          setAllaKunder(
            items.map(({ namn, kundnr, fastighet, kundgrupp, hamtstallen }) => ({
              namn,
              kundnr,
              fastighet,
              kundgrupp,
              hamtstallen,
            }))
          );
          setReady(true);
        })
        .catch(() => {
          console.log("token remove 1", localStorage.getItem("token"));
          localStorage.removeItem("token");
          saveUsername("");
        });
    }
  }, [ready, username]);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <div style={{ textAlign: "center" }}>
            <Logo />

            <p style={{ color: "#80887f", fontWeight: "bold" }}> Loading...</p>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
