/* eslint-disable no-unused-vars */
/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/css/material-dashboard-pro-react.css";
import api from "app/api";
import Report from "views/Dashboard/Report";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useIsLoggedIn } from "app/auth";
import { useUsername } from "app/auth";
import { useReady } from "app/store";

import * as Sentry from "@sentry/react";
/*
Sentry.init({
  dsn:
    "https://28c36b67081afcfc6307ee8234c33e36@o4506593204174848.ingest.sentry.io/4506593231175680",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});*/

const queryClient = new QueryClient();

const hist = createBrowserHistory();

function App() {
  const [username] = useUsername();
  const [ready] = useReady();
  const isLoggedIn = useIsLoggedIn();

  React.useEffect(() => {
    if (username && username !== "A") {
      const tagManagerArgs = {
        gtmId: "GTM-W4Q2PVN",
        events: {
          threeMClick: "3 Months Click",
          sixMClick: "6 Months Click",
          twelveMClick: "12 Months Click",
          createPdf: "Create PDF Click",
          excelStats: "Excel Statistics Click",
          graphInPDF: "Graph in PDF",
          graphTitle: "Graph Title",
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, [username]);

  React.useEffect(() => {
    if (localStorage.getItem("token") && username) {
      const promise = username === "A" ? api.Account.Kundnr(username) : api.Profile.My(username);

      promise.catch((error) => {
        // console.log({ error });
        //console.log("token remove 12", localStorage.getItem("token"));
        // localStorage.removeItem("token");
      });
    }
  }, [username]);

  // console.log({ token: localStorage.getItem("token") });

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={hist}>
        {ready ? (
          <Switch>
            <Route path="/admin" component={AdminLayout} />
            <Route path="/report" component={Report} />
            <Redirect to="/admin/dashboard" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Redirect to="/auth/login-page" />
          </Switch>
        )}
      </Router>
    </QueryClientProvider>
  );
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App />, document.getElementById("root"));
